import { Injectable } from '@angular/core';
import { AppState } from '@app/state/app.state';
import { getActiveFastPlay } from '@app/state/selectors/global-scope.selectors';
import { Store } from '@ngrx/store';
import { AllowedDevicePlatformsType } from '@providers/device-ks/device-platform.interface';
import { DeviceKS, MetricsService } from '@providers/providers';
import { clone, find, findIndex, forEach } from 'lodash-es';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { IMedia } from '../../interfaces/interfaces';
import { CountryService } from '../country/country';
import { KSHelpers } from '../helpers/ks-helpers';
import { Platform } from '../platform/platform';
import { RootScopeAdapter } from '../root-scope-adapter/root-scope-adapter';
import { getMP4SourceAndTone } from './player.helpers';
import { VideoJSServiceInterface } from './videojs.service.interface';
@Injectable()
export class PlayerService {
  onLoadVideo: Subject<any> = new Subject();
  onReadyVideo: Subject<any> = new Subject();
  onProgress: Subject<any> = new Subject();
  onTimeSeconds: Subject<any> = new Subject();
  playerState: Subject<boolean> = new Subject();
  deleteSong: Subject<IMedia> = new Subject();
  onNextSong: Subject<IMedia> = new Subject();
  onPlayClip: Subject<any> = new Subject();
  onPreviusSong: Subject<IMedia> = new Subject();
  onErrorVideo: Subject<Record<string, any>> = new Subject();
  onFinishSong: Subject<IMedia> = new Subject();
  onFinishAll: Subject<any> = new Subject();
  onFullyLoadedVideo: Subject<any> = new Subject();
  onUpdateFavoriteOneSong: Subject<any> = new Subject();

  onTimeLess10: Subject<any> = new Subject();
  onTimeM5: Subject<any> = new Subject();
  onTime30: Subject<IMedia> = new Subject();

  onTimeM10: Subject<IMedia> = new Subject();
  onTimeM80: Subject<IMedia> = new Subject();

  urlBreakPlayer = ['localhost'];
  // let urlBreakPlayer=[];
  // 'localhost','karaokesmart.ngrok.io','192.168.1.19'
  pl: any; // videojs.Player;

  list: IMedia[] = [];
  auxList: IMedia[] = [];

  nextToDel: IMedia; // Proximo a eliminar
  indexSongCurrent = 0;

  /**
   * timeNot : objeto que controla el tiempo de notificacion de eventos
   */
  timeNot = {
    timeM1: false,
    timeM5: false,
    time30: false,
    timeM40: false,
    timeM10: false,
    timeM60: false,
    timeM80: false,
    reset: function reset() {
      this.timeM1 = false;
      this.time30 = false;
      this.timeM10 = false;
      this.timeM40 = false;
      this.timeM60 = false;
      this.timeM80 = false;
    },
  };

  progressAux = 0;
  bufferAux = 0;

  errorFlag = false;

  hlsRenditionBlacklistedEventSentForSongId : number = 0;
  hlsRenditionSwitchedEventSentForSongId : number = 0;

  private playThisBand = false;

  private currentClip: IMedia = {} as IMedia;

  private videojs; // VideoJSPlayer;

  constructor(
    public helpers: KSHelpers,
    private country: CountryService,
    private rootScopeAdapter: RootScopeAdapter,
    private platform: Platform,
    private deviceKS: DeviceKS,
    private metrics: MetricsService,
    private store: Store<AppState>,
  ) {
    if (this.platform.isBrowser()) {
      this.onUpdateFavoriteOneSong.subscribe(data => {
        this.currentClip.like = data.value;
      });
    }
  }

  loadVideoJS(videoService: VideoJSServiceInterface) {
    if (!!this.videojs) {
      return;
    }
    try {
      this.videojs = videoService.getVideoJS();
      this.videojs.use('*', () => ({
        setSource: (srcObj, next) => {
          // console.log('src obj es este', srcObj);
          // pass null as the first argument to indicate that the source is not rejected
          next(null, srcObj);
        },
      }));
    } catch (e) {
      console.log(' no carga videojs');
    }
  }

  getCurrentClip() {
    return this.currentClip;
  }

  setCurrentClip(song: IMedia) {
    this.currentClip = song;
  }

  onFinish = async () => {
    console.log('ocurre on finish player service', this);
    this.onFinishSong.next();
    if (this.playThisBand) {
      this.onFinishAll.next();
      this.indexSongCurrent = 0;
      return;
    }

    if (this.currentClip.mode !== 'game') {
      await this.processNextSong();

      if (this.list.length <= 1) {
        this.onFinishAll.next();
        this.indexSongCurrent = 0;
      }
    }
  };

  async processNextSong() {
    console.log('ocurre procesamiento de la siguiiente cancion', this.currentClip);
    const songToDelete = clone(this.currentClip);
    console.log('song to delete', songToDelete);
    if (this.list.length > 1) {
      console.log('voy a ejecutar la next song');
      await this.next(songToDelete.uid);
    }
    this.deleteSong.next(songToDelete);
  }

  onReadyPlayer(callback: () => any): Subscription {
    return this.playerState.subscribe(ready => {
      if (ready) {
        callback();
      }
    });
  }

  getIndexSongCurrent() {
    return this.indexSongCurrent;
  }
  setIndexSongCurrent(position) {
    if (-1 < position) {
      this.indexSongCurrent = position;
    }
  }

  makeActive(clip: IMedia) {
    let temp;
    forEach(this.list, (s, i) => {
      if (s.uid === clip.uid) {
        s.activeTab = true;
      } else {
        s.activeTab = false;
        if (this.nextToDel) {
          if (this.nextToDel.uid === s.uid) {
            temp = i;
          }
        }
      }
    });
    if (temp !== undefined) {
      this.list.splice(temp, 1);
      this.nextToDel = null;
    }
  }
  getRandomize() {
    // generate a number between 1 a 10000
    return Math.floor(Math.random() * 10000 + 1);
  }

  registerHlsEvents() {
    // DOCUMENTATION: https://github.com/videojs/http-streaming?tab=readme-ov-file#vhs-usage-events
    this.pl.tech().on('usage', (e) => {
      /**************/
      // 'hls-rendition-blacklisted' when TS file request returns a 403 forbidden response
      // console.log('this.pl this.hlsRenditionBlacklistedEventSentForSongId', this.hlsRenditionBlacklistedEventSentForSongId);
      if (e.name === 'hls-rendition-blacklisted' &&
        this.hlsRenditionBlacklistedEventSentForSongId !== this.currentClip.id) {
        const currentTime = this.pl.currentTime();
        const playerHls = (this.pl.tech() as any).hls;
        const targetMedia = playerHls.playlists.media();
        const lastSegment = targetMedia.segments.find(seg => {
          return seg.start === undefined && seg.end === undefined;
        });
        // console.log('this.pl HlsRenditionBlacklisted rendition ' + lastSegment.resolvedUri + ' at ' + currentTime + ' seconds');
        this.metrics.sendEvent('HlsRenditionBlacklisted', {
          Song: {
            name: this.currentClip.name,
            id: this.currentClip.id,
          },
          Segment: lastSegment.resolvedUri,
          CurrentTime: currentTime,
        });
        // Avoid sending the same event multiple times for the same song (even if the rendition changes)
        this.hlsRenditionBlacklistedEventSentForSongId = this.currentClip.id;
      }
      /**************/
    });
    /*************/
    /******* Detecting changes in quality *******/
    let tracks = this.pl.textTracks();
    let segmentMetadataTrack;
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].label === 'segment-metadata') {
        segmentMetadataTrack = tracks[i];
      }
    }
    let previousPlaylist;
    if (segmentMetadataTrack) {
      segmentMetadataTrack.on('cuechange', () => {
        let activeCue = segmentMetadataTrack.activeCues[0];
        if (activeCue) {
          // console.log('this.pl this.hlsRenditionSwitchedEventSentForSongId', this.hlsRenditionSwitchedEventSentForSongId);
          if (previousPlaylist && previousPlaylist !== activeCue.value.playlist &&
            this.hlsRenditionSwitchedEventSentForSongId !== this.currentClip.id) {
            const currentTime = this.pl.currentTime();
            // console.log(
            //   'this.pl Switched from rendition ' + previousPlaylist +
            //   ' to rendition ' + activeCue.value.playlist + ' at ' + currentTime + ' seconds'
            // );
            this.metrics.sendEvent('SwitchHlsRendition', {
                Song: {
                  name: this.currentClip.name,
                  id: this.currentClip.id,
                },
                PreviousPlaylist: previousPlaylist,
                NewPlaylist: activeCue.value.playlist,
                CurrentTime: currentTime,
            });
            // Avoid sending the same event multiple times for the same song (even if the rendition changes)
            this.hlsRenditionSwitchedEventSentForSongId = this.currentClip.id
          }
          previousPlaylist = activeCue.value.playlist;
        }
      });
    }
    /**************/
  }

  initialize(divId: string, opt, videoJSService: VideoJSServiceInterface) {
    this.loadVideoJS(videoJSService);

    this.indexSongCurrent = this.indexSongCurrent || 0;
    if (this.list.length === 0) {
      return;
    }
    // en caso no haya cancion por error de la url
    this.setCurrentClip(this.list[this.indexSongCurrent]);

    try {
      const container = document.getElementById(divId);

      const playerOptions = {
        autoplay: true,
        controls: false,
        sources: this.list[this.indexSongCurrent].sources,
        html5: {
          vhs: {
            overrideNative: true,
            useCueTags: true
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false
        }
      };
      this.pl = this.videojs(container, playerOptions, () => {
        // console.log('onPlayerReady', this.pl);
      });

      const onLoadMeta = ev => {
        // that.timeNot.reset();
        // const currentSource = this.pl.currentSource();
        // this.currentClip = currentSource;
        this.currentClip.duration = this.pl.duration();
        this.registerHlsEvents();
        this.onReady(ev, null, this.currentClip);
      };

      this.pl.on('loadedmetadata', onLoadMeta);

      this.pl.on('load', (e, api, data) => {
        this.onLoadVideo.next();
      });
      this.makeActive(this.currentClip);
      this.pl.on('timeupdate', this.onProgressActions.bind(this));

      this.pl.on('error', this.onError.bind(this));
      // this.pl.on('contenterror', this.onError.bind(this));
      this.pl.off('ended', () => {
        console.log('OFF END EVENT');
        this.onFinish();
      });
      this.pl.on('ended', () => {
        console.log('ON END EVENT');
        this.onFinish();
      });
    } catch (e: any) {
      this.onErrorVideo.next(e);
    }
  }

  afterUnload() {
    // servicios del player al parecer
    this.timeNot.reset();
    this.list = this.auxList;
    this.auxList = null;
  }

  onReady(ev, config, clip: IMedia) {
    clip.lastTimeSended = 0;
    this.timeNot.reset();
    this.setCurrentClip(clip);
    this.progressAux = 0;
    this.bufferAux = 0;
    this.makeActive(clip);
    // console.log("ready",clip);
    this.errorFlag = false;

    this.onPlayClip.next({
      clip,
      mixpanel: {
        artist: clip.artist,
        song: clip.name,
        idSong: clip.id,
        namePlaylist: clip.namePlaylist ? clip.namePlaylist : 'fuera de toptrack',
        tiene_tono: !!clip.current_tone,
        tono: clip?.current_tone ?? 0,
        provider: clip.provider,
        video: clip.src,
        where: clip.where || null,
        search_keyword: clip.search_keyword,
      },
    });
  }

  onError(element) {
    const error = this.pl?.error() || {
      code: 'NO_CODE',
      message: 'NO_MESSAGE',
    };

    this.onErrorVideo.next(error);

    if (error.code === 4 && !this.errorFlag) {
      this.errorFlag = true;
      this.pl?.pause();
      this.pl?.src(this.currentClip.sources);
      this.pl?.play();
    }
  }

  async getCurrentActiveFastPlay() {
    const activeFastPlay = await this.store
      .select(getActiveFastPlay)
      .pipe(take(1))
      .toPromise();
      return activeFastPlay;
  }

  async onProgressActions(e) {
    let time = this.pl.currentTime();
    // jQuery('#player').removeClass('is-seeking');
    this.currentClip.currentTime = time;
    time = Math.floor(time);
    this.currentClip.time = time;
    if (time <= 10) {
      this.onTimeLess10.next({ time });
    }
    switch (time) {
      case 1:
        if (this.country.currentCountry !== 'CO' && this.country.currentCountry !== 'MX' && !this.timeNot.timeM1) {
          this.timeNot.timeM1 = true;
        }
        break;
      case 5:
        if (!this.timeNot.timeM5) {
          this.timeNot.timeM5 = true;
          this.onTimeM5.next();
        }
        break;
      case 8:
        const fastPlay = await this.getCurrentActiveFastPlay();
        console.log('this.getCurrentActiveFastPlay()', fastPlay);
        if (fastPlay) {
          if (!this.timeNot.time30) {
            this.timeNot.time30 = true;
            this.onTime30.next(this.currentClip);
            this.pl.currentTime(this.currentClip.duration - 10);
          }
        }
        break;
      case 30:
        if (!this.timeNot.time30) {
          this.timeNot.time30 = true;
          this.onTime30.next(this.currentClip);
          if (this.urlBreakPlayer.indexOf(window.location.hostname) !== -1) {
            // console.log("seekTo");
            // this.pl.seekTo(90);
            // this.pl.seek(this.currentClip.duration - 15);
          }
        }
        break;
      case (parseInt(this.currentClip.duration, 10) / 5) * 4:
        if (!this.timeNot.timeM80) {
          this.timeNot.timeM80 = true;
          this.onTimeM80.next(this.currentClip);
        }
        break;
      case parseInt(this.currentClip.duration, 10) / 2:
        this.nextToDel = this.currentClip;
        break;
      case parseInt(this.currentClip.duration, 10) - 10:
        if (!this.timeNot.timeM10) {
          this.timeNot.timeM10 = true;
          this.onTimeM10.next(this.currentClip);
        }
        break;
      default:
    }
    const progress = Math.floor((time / this.currentClip.duration) * 1280);
    const buffer = Math.floor((this.currentClip.buffer / this.currentClip.duration) * 100);
    this.currentClip.currentBufferPercent = buffer || 0;
    this.onProgress.next(this.currentClip);
    if (Math.round(this.currentClip.buffer) >= Math.round(this.currentClip.duration)) {
      this.onFullyLoadedVideo.next();
    }
    if (progress !== this.progressAux && progress > this.progressAux) {
      this.progressAux = progress;
      this.onTimeSeconds.next(this.currentClip);
    }
  }

  shutdown() {
    this.indexSongCurrent = 0;
    // this.pl.shutdown();
    this.pl?.dispose();
  }
  findByUid(uid) {
    return song => song.uid === uid;
  }
  deleteSongForPosition(posiSong) {
    const songDeleted = this.list.splice(posiSong, 1);
    if (!this.playThisBand && songDeleted && songDeleted.length) {
      // let posiuid = this.list.indexOf(
      //   this.list.filter(this.findByUid(songDeleted[0].uid))[0]
      // );
      const songToDelete = find(this.list, { uid: songDeleted[0].uid });
      this.deleteSong.next(songToDelete);
      // this.list.splice(posiuid, 1);
    }
  }

  seekPerc(perc) {
    const to = this.currentClip.duration * perc * 0.01;
    // console.log(to, 'this.pl.seek', this.pl.seek);
    // this.pl.seek(to);
    const duration = this.pl.duration() || 0;
    this.pl.currentTime(duration * to);
  }

  load(song: IMedia) {
    if (song) {
      if (typeof song.like !== 'undefined') {
        this.updateFavoriteOneSong(song);
      }
      // this.pl.src(song.sources);
      if (this.pl) {
        // console.log('song is', song);
        // console.log('first source', song.sources[0], 'with tone', song.current_tone ?? 0);
        if (this.deviceKS.devicePlatform.name === AllowedDevicePlatformsType.movistar) {
          this.pl.reset();
          this.pl.ready(() => {
            const player = document.getElementById('player_html5_api');
            player.style.width = '100%';
            player.style.height = '100%';
          });
        }
        this.pl.src(song.sources[0].src);
        this.pl.load();
        this.pl.on('error', () => {
          const newSource = getMP4SourceAndTone(song)[0];
          const codeError = this.pl.error().code;
          if (codeError === 3 || codeError === 4) {
            this.onErrorVideo.next({
              ...this.pl.error(),
              errorType: 'compatibility',
            });
            console.log('compatibility error - changing source', newSource, 'with tone ', song.current_tone ?? 0);
            this.pl.src(newSource.src);
            this.pl.load();
          } else {
            this.onErrorVideo.next({
              ...this.pl.error(),
              errorType: 'untracked',
            });
            console.log('untracked error - changing source', newSource, 'with tone ', song.current_tone ?? 0);
            this.pl.src(newSource.src);
            this.pl.load();
          }
        });
        this.setCurrentClip(song);
      } else {
        throw new Error('Player not initialized');
      }
    }
  }

  updateFavoriteOneSong(song) {
    this.onUpdateFavoriteOneSong.next({
      value: song.like,
    });
  }
  setPlayThisBand(val) {
    this.playThisBand = val;
  }
  getPlayThisBand() {
    return this.playThisBand;
  }

  getUid(idSong) {
    return idSong + '_' + new Date().getTime() + '_' + this.getRandomize();
  }

  cancelLoadToback() {
    try {
      if (this.pl) {
        this.pl.pause();
        // this.pl.unload();
        this.pl.src(null);
        this.pl.dispose();
      }
    } catch (e) {
      console.log(e);
    }
  }
  /**
   * next : funcion que reproduce la siguiente cancion en el smartlist, si se recibe uid se da
   * siguiente cancion apartir de la cancion dada.
   *
   * @returns si logro dar siguiente retorna TRUE, sino FALSE
   */
  next(uid?: string): Promise<IMedia> {
    const uidInitToNext = uid ? uid : this.currentClip.uid;
    const currentSongIndex = findIndex(this.list, { uid: uidInitToNext });
    // console.log('current song iindex', currentSongIndex);
    const nextSongIndex = currentSongIndex === this.list.length - 1 ? 0 : currentSongIndex + 1;
    // console.log('next song index', nextSongIndex);
    if (currentSongIndex >= 0) {
      this.indexSongCurrent = nextSongIndex;
      const nextSong = this.list[nextSongIndex];
      // console.log('envia next song', nextSong);
      this.onNextSong.next(nextSong);
      return Promise.resolve(nextSong);
    }
    // console.log('rechazado con null next song');
    return Promise.reject(null);
  }
  /**
   * previus : funcion que reproduce la siguiente cancion en el smartlist, si se recibe uid se da
   * siguiente cancion apartir de la cancion dada.
   *
   * @returns si logro dar siguiente retorna TRUE, sino FALSE
   */
  previus(uid?: string): Promise<IMedia> {
    const uidInitToPrev = uid ? uid : this.currentClip.uid;
    const currentSongIndex = findIndex(this.list, { uid: uidInitToPrev });
    const prevSongIndex = currentSongIndex === 0 ? this.list.length - 1 : currentSongIndex - 1;
    if (currentSongIndex >= 0) {
      this.indexSongCurrent = prevSongIndex;
      const previousSong = this.list[prevSongIndex];
      this.onPreviusSong.next(previousSong);
      return Promise.resolve(previousSong);
    }
    return Promise.reject(null);
  }
  play() {
    if (this.pl) {
      this.pl.play();
    }
    return true;
  }
  pause() {
    if (this.pl) {
      this.pl.pause();
    }
    return false;
  }
  resume() {
    if (this.pl) {
      this.pl.play();
    }
    return true;
  }

  getNextSong() {
    const actualSongIndex = findIndex(this.list, (s: any) => s.uid === this.currentClip.uid);
    // console.log(actualSongIndex, that.currentClip, 'that.currentClip');
    if (actualSongIndex + 1 < this.list.length) {
      return this.list[actualSongIndex + 1];
    } else {
      return null;
    }
  }

  nextInList(uid: string) {
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].uid === uid) {
        if (i < this.list.length - 1) {
          const actual = this.list[i];
          const temp = this.list[i + 1];
          this.list[i + 1] = actual;
          this.list[i] = temp;
          // this.list        = angular.copy(this.nameList);
        }
        break;
      }
    }
  }

  backInList(uid) {
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].uid === uid) {
        if (i > 0) {
          const actual = this.list[i];
          const temp = this.list[i - 1];
          this.list[i - 1] = actual;
          this.list[i] = temp;
          // this.list        = angular.copy(this.nameList);
        }
        break;
      }
    }
  }

  activateSong(clip) {
    this.makeActive(clip);
  }

  playSong(song: IMedia) {
    this.load(song);
    this.timeNot.reset();
  }
}
