import { Injectable } from '@angular/core';
import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';

import { changeToMobileEventName, changeToMobilePropsName } from './changeEventNameAndProps';

@Injectable()
export class CustomerIoService {
  enabled = false;
  ready = false;
  analytics: AnalyticsBrowser;
  private writeKey = '00cc26ef82d3a5064bba';

  constructor() {
    console.log('CustomerIOService Constructor');
    if (!this.ready) {
      this.analytics = this.load();
    }
  }

  load() {
    // eslint-disable-next-line curly
    if (!this.enabled) return null;
    this.ready = true;
    return AnalyticsBrowser.load(
      { writeKey: this.writeKey },
      {
        initialPageview: false,
        disableClientPersistence: true,
      }
    );
  }

  async sendEvent(eventName: string, eventData?: Record<string, any>) {
    // eslint-disable-next-line curly
    if (!this.enabled) return;
    await this.analytics.track(
      changeToMobileEventName(eventName),
      {
        ...changeToMobilePropsName(eventName, eventData),
        id: eventData?.email,
        email: eventData?.email,
        userId: eventData?.userId,
      },
      {
        event: changeToMobileEventName(eventName),
        traits: {
          ...changeToMobilePropsName(eventName, eventData),
          id: eventData?.email,
          email: eventData?.email,
          userId: eventData?.userId,
        },
        timestamp: new Date().toISOString(),
        context: {
          traits: {
            ...changeToMobilePropsName(eventName, eventData),
            id: eventData?.email,
            email: eventData?.email,
            userId: eventData?.userId,
          },
        },
      }
    );
  }

  async identify(key: string, data?: Record<string, any>) {
    // eslint-disable-next-line curly
    if (!this.enabled) return;
    await this.analytics.identify(
      {
        id: data?.email,
        userId: key,
        email: data?.email,
      },
      {
        ...changeToMobilePropsName('identify', data),
        id: data?.email,
        userId: key,
        email: data?.email,
      },
      {
        traits: {
          ...changeToMobilePropsName('identify', data),
          id: data?.email,
          userId: key,
          email: data?.email,
        },
        timestamp: new Date().toISOString(),
        context: {
          traits: {
            ...changeToMobilePropsName('identify', data),
            id: data?.email,
            userId: key,
            email: data?.email,
          },
        },
      }
    );
  }

  async reset() {
    // eslint-disable-next-line curly
    if (!this.enabled) return;
    await this.analytics.reset();
  }
}
