import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { setLoading } from '@app/state/actions/global-scope.actions';
import { AppState } from '@app/state/app.state';
import { getGoOutApp, getIsLoading } from '@app/state/selectors/global-scope.selectors';
import { Store } from '@ngrx/store';
import { Config } from '@providers/config/config';
import { RemoteContactDataService } from '@providers/config/remote-contact-data.service';
import { DeviceKS } from '@providers/device-ks/device-ks';
import { AllowedDevicePlatformsType } from '@providers/device-ks/device-platform.interface';
import { KSFirebase } from '@providers/firebase/ksfirebase';
import { FriendsService } from '@providers/friends/friends';
import { GtvZone } from '@providers/gtv/gtv';
import { isActionPlaying, isActionPlayingQueue } from '@providers/helpers/helpers';
import { MetricsService } from '@providers/metrics/metrics.service';
import { ModalService } from '@providers/modal/modal';
import { CountryService, LocalStorage, LocationService, SessionService } from '@providers/providers';
import { User } from '@providers/user/user';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'app';
  appLoaded = false;

  becomePremiumSubscription?: Subscription;
  remoteLoginSubscription?: Subscription;
  goOutApp$ = this.store.select(getGoOutApp);
  isLoading$ = this.store.select(getIsLoading);
  isLoadingActive = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private cdr: ChangeDetectorRef,
    private country: CountryService,
    private _ngZone: NgZone,
    private location: Location,
    public metrics: MetricsService,
    private route: ActivatedRoute,
    private router: Router,
    private config: Config,
    public deviceKS: DeviceKS,
    public modal: ModalService,
    public friends: FriendsService,
    public gtvzone: GtvZone,
    private renderer: Renderer2,
    private localStorage: LocalStorage,
    private store: Store<AppState>,
    private session: SessionService,
    private user: User,
    private ksFirebase: KSFirebase,
    private locationService: LocationService,
    private remoteContactDataServ: RemoteContactDataService
  ) {
    // this.mixpanel.register({singedSongs: this.freeSongs.getFreeSongs()});
    // this.mixpanel.sendEvent('abrioApp');
    if (this.deviceKS.devicePlatform) {
      this.deviceKS.devicePlatform.onResume({
        router: this.router,
        user: this.user,
        session: this.session,
      });
    }
    this.setTimeInitLoadAppComponent();
  }

  get appNotAvailable() {
    return this.user.sessionError;
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    event.preventDefault();
  }

  ngOnInit() {
    this.deviceKS.devicePlatform?.$deviceEvent.subscribe(event => {
      if (event.action === 'navigateTo') {
        this.router.navigate([event.data]);
      }
    });

    this.goOutApp$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (!value) {
        return;
      }
      if (!this.modal.modalActive) {
        this.modal.stateModal('closeApp', true);
        this.cdr.detectChanges();
      } else {
        this.modal.stateModal('', false);
        setTimeout(() => {
          if (!this.modal.modalActive) {
            this.gtvzone.setDefaultSelected('.defaultOption', 'panelZona');
          }
        });
      }
    });

    this.user.goRootSamsung.subscribe(() => {
      this.router.navigate(['welcome']);
    });

    this.onRouting();
    this.user.onReady(() => {
      this.userMigrations();
    });
    this.session.session$.subscribe(sess => {
      if (!sess) {
        return;
      }
      this.listenFirebase();
    });

    this.remoteContactDataServ.getRemoteContactData();

    this.isLoading$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      console.log('999 App loading animation this.isLoading$', value);
      this.isLoadingActive = value;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  setTimeInitLoadAppComponent() {
    // this.localStorage.setItem('tiempoInicialCargaApp', new Date().getTime().toString());
    (window as any).tiempoInicialCargaApp = new Date().getTime();
  }

  listenFirebase() {
    this.becomePremiumSubscription?.unsubscribe();
    this.becomePremiumSubscription = this.ksFirebase.becomePremiumAction.subscribe(this.onBecomePremium.bind(this));
    this.remoteLoginSubscription?.unsubscribe();
    this.remoteLoginSubscription = this.ksFirebase.remoteLoginAction.subscribe(this.onRemoteLogin.bind(this));
  }

  onRemoteLogin(value) {
    if (!value) {
      return;
    }

    // Leer desde aqui platform_from
    // this.session.currentRealTimeSession.platform_from;

    if (this.user.premium) {
      this.router.navigate(['toptrack']);
    } else {
      if (this.config.countryIsNotFreemium(this.country.currentCountry)) {
        if (this.session.currentRealTimeSession.platform_from === 'web') {
          if (this.deviceKS.devicePlatform.name === AllowedDevicePlatformsType.movistar) {
            this.router.navigate(['toptrack']);
          } else if (this.deviceKS.devicePlatform.name === AllowedDevicePlatformsType.vizio) {
            this.router.navigate(['subscription']);
          } else {
            this.router.navigate(['waiting-web-checkout']);
          }
        } else {
          if (this.deviceKS.devicePlatform.name === AllowedDevicePlatformsType.movistar) {
            this.router.navigate(['toptrack']);
          } else {
            this.router.navigate(['subscription']);
          }
        }
      } else if (this.config.isAvailableNewFreemium()) {
        this.router.navigate(['toptrack']);
      } else {
        if (isActionPlaying(this.route.snapshot.queryParams.action)) {
          this.router.navigate(['player'], { queryParams: this.route.snapshot.queryParams });
        } else if (isActionPlayingQueue(this.route.snapshot.queryParams.action)) {
          this.router.navigate(['player'], { queryParams: this.route.snapshot.queryParams });
        } else {
          if (this.user.premium) {
            this.router.navigate(['toptrack']);
          } else {
            this.router.navigate(['toptrack']);
          }
        }
      }
    }
  }

  onBecomePremium(value) {
    if (value) {
      this._ngZone.run(() => {
        if (this.user.authenticated) {
          this.user.updateToPremium();
        }
        this.router.navigate(['premium-onboarding']);
      });
    }
  }

  ngAfterViewInit() {
    console.log('AppComponent ngAfterViewInit');
    console.log('EL APP YA CARGO AQUI Y ESTA HACIENDO EL AFTERVIEW INIT');
    // this.metrics.setParametersDevices(this.deviceKS, this.user);
    this.metrics.timeLoadApp(this.country.currentCountry);

    this.runLaunchEventFromQueryParams(); // TESTING
  }

  onRouting() {
    this.router.events.subscribe(event => {
      // console.log('ON ROUTING', event as RouterEvent);
      this.navigationInterceptor(event as RouterEvent);
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.store.dispatch(setLoading({ isLoadingValue: true }));
      this.cdr.detectChanges();
    }
    if (event instanceof NavigationEnd) {
      this.store.dispatch(setLoading({ isLoadingValue: false }));
      if (!this.appLoaded) {
        this.appLoaded = true;
        setTimeout(() => {
          this.renderer.removeChild(document.body, document.body.querySelector('.modal-init'));
          this.cdr.detectChanges();
        }, 1000);
      } else {
        this.cdr.detectChanges();
      }
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.store.dispatch(setLoading({ isLoadingValue: false }));
      this.cdr.detectChanges();
    }
    if (event instanceof NavigationError) {
      this.store.dispatch(setLoading({ isLoadingValue: false }));
      this.cdr.detectChanges();
      let message;
      if (event.error) {
        if (typeof event.error === 'string') {
          message = event.error;
        } else {
          message = event.error.message || 'Sin Mensaje';
        }
      }
      this.metrics.sendEvent('NavigationError', { url: event.url, message });
    }
  }

  userMigrations() {
    if (this.user && this.user.currentUser) {
      this.metrics.identify(this.user.currentUser, this.user.currentUser.id);
    }
  }

  /**
   * Dispara el launchEvent si se pasan los query params con la data necesaria.
   *
   *  - `launchEvent_tokenUser` (opcional si hay una sesión activa)
   *  - `launchEvent_requestTokenId`
   */
  runLaunchEventFromQueryParams() {
    // TODO Comprobar que sólo se pueda ejecutar en entorno seguro

    const requestTokenId = this.locationService.getUrlParameter('launchEvent_requestTokenId');

    if (requestTokenId) {
      console.warn('Ejecutando manualmente el launchEvent...');

      const tokenUser: string | undefined =
        this.locationService.getUrlParameter('launchEvent_tokenUser') ||
        (this.user.currentUser?.token as string | undefined) ||
        this.localStorage.getCachedItem('tokenUser') ||
        undefined;

      if (tokenUser) {
        if (this.deviceKS.devicePlatform?.lauchEvent) {
          this.deviceKS.devicePlatform.lauchEvent.next({ requestTokenId, tokenUser });
        } else {
          console.error('  El device platform no está inicializado');
        }
      } else {
        console.error(`  Falta el parámetro 'launchEvent_tokenUser' y no hay ninguna sesión activa.`);
      }
    } else {
      console.warn('Skip runLaunchEventFromQueryParams() sin el query "launchEvent_requestTokenId"');
    }
  }
}
